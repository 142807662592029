
import {defineComponent} from "vue";
import {LeftCircleFilled} from '@ant-design/icons-vue'
import {useRouter} from "vue-router";

export default defineComponent({
  name: "GoBackIcon",
  props: {
    redirect: {
      type: [String, Boolean],
      required: false,
      default: false
    }
  },
  components:{
    LeftCircleFilled
  },
  setup(props, ctx){
    const router = useRouter();
    const routeBack = () => {
      if (!props.redirect){
        router.back();
      }else {
        router.push({
          name: <string>props.redirect,
        })
      }
    }
    return {
      routeBack
    }
  },
  data() {
    return {}
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },
  },
})
