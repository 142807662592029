
import GoBackIcon from "@/components/system/GoBackIcon.vue";
import LogoutIcon from "@/components/system/LogoutIcon.vue";
import TeacherInformation from "@/components/system/TeacherInformation.vue";
import {onMounted, defineComponent} from "vue";
import {courseSelectBill} from "@/views/subjects/CourseSelect/CourseSelect";
import {Col, Radio, Row} from 'ant-design-vue'

export default defineComponent({
  name: "CourseSelect",
  components: {
    TeacherInformation,
    LogoutIcon,
    GoBackIcon,
    ARadioButton: Radio.Button,
    ARadioGroup: Radio.Group,
    ARow: Row,
    ACol: Col,
  },
  setup() {
    const {
      refData,
      init,
      onCourseClick,
    } = courseSelectBill();


    onMounted(() => {
      init()
    })
    return {
      ...refData,
      onCourseClick,
    }
  },
  data() {
    return {}
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },
  },
})
