import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "course-select" }
const _hoisted_2 = { class: "version" }
const _hoisted_3 = { class: "subjects" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "course scroll-bar-hidden" }
const _hoisted_6 = { class: "course-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_go_back_icon = _resolveComponent("go-back-icon")!
  const _component_logout_icon = _resolveComponent("logout-icon")!
  const _component_teacher_information = _resolveComponent("teacher-information")!
  const _component_a_radio_button = _resolveComponent("a-radio-button")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_go_back_icon),
      _createVNode(_component_logout_icon),
      _createVNode(_component_teacher_information),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_a_radio_group, {
          value: _ctx.version,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.version) = $event)),
          "button-style": "solid"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.versionList, (i, k) => {
              return (_openBlock(), _createBlock(_component_a_radio_button, {
                key: k,
                value: i,
                style: {width: '110px', height: '45px', border: '2px solid #FFF', lineHeight: '45px', fontSize: '30px', color:'#FFF'}
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(i), 1)
                ]),
                _: 2
              }, 1032, ["value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["value"])
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subjectList, (i, k) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["subjects-item", {'choose': i === _ctx.subject}]),
          key: k,
          onClick: ($event: any) => (_ctx.subject = i)
        }, _toDisplayString(i), 11, _hoisted_4))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_a_row, {
        type: "flex",
        align: "middle"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.courseList, (i) => {
            return (_openBlock(), _createBlock(_component_a_col, {
              span: 8,
              key: i.id,
              onClick: ($event: any) => (_ctx.onCourseClick(i))
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("span", null, _toDisplayString(i.lessonName), 1)
                ])
              ]),
              _: 2
            }, 1032, ["onClick"]))
          }), 128))
        ]),
        _: 1
      })
    ])
  ]))
}