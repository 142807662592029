import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/image/timetable/go_back.png'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "go-back-icon",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.routeBack && _ctx.routeBack(...args)))
  }, _cache[1] || (_cache[1] = [
    _createElementVNode("img", {
      src: _imports_0,
      alt: "",
      style: {"width":"52px"}
    }, null, -1)
  ])))
}