import {message} from 'ant-design-vue'
import { VNode } from 'vue'

const key = 'pageLoading'

export const onPageLoadingStart = (content: string|VNode = '获取数据中...') => {
  message.loading({
    content: content,
    key,
    duration: 0,
  })
}

export const onPageLoadingEnd = (content?: string|VNode, type?:any) => {
  if (content){
    if (type){
      message.open({
        content: content,
        duration: 1,
        type: type,
        key
      } )
    }else{
      message.success({
        content: content,
        key,
        duration: 1,
      })
    }
  }else{
    message.destroy()
  }

}
